@font-face {
  font-family: "Pretendard-Black";
  font-style: normal;
  font-weight: 900;
  src: url("./Pretendard-Black.woff") format("woff");
}
@font-face {
  font-family: "Pretendard-ExtraBold";
  font-style: normal;
  font-weight: 800;
  src: url("./Pretendard-ExtraBold.woff") format("woff");
}
@font-face {
  font-family: "Pretendard-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("./Pretendard-Bold.woff") format("woff");
}
@font-face {
  font-family: "Pretendard-SemiBold";
  font-style: normal;
  font-weight: 600;
  src: url("./Pretendard-SemiBold.woff") format("woff");
}
@font-face {
  font-family: "Pretendard-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("./Pretendard-Medium.woff") format("woff");
}
@font-face {
  font-family: "Pretendard-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("./Pretendard-Regular.woff") format("woff");
}
@font-face {
  font-family: "Pretendard-Light";
  font-style: normal;
  font-weight: 300;
  src: url("./Pretendard-Light.woff") format("woff");
}
@font-face {
  font-family: "Pretendard-ExtraLight";
  font-style: normal;
  font-weight: 200;
  src: url("./Pretendard-ExtraLight.woff") format("woff");
}
@font-face {
  font-family: "Pretendard-Thin";
  font-style: normal;
  font-weight: 100;
  src: url("./Pretendard-Thin.woff") format("woff");
}
